@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: "FuturaPTLight", sans-serif;
}

.sections {
  display: flex;
  flex-direction: column;
  transition: transform 0.55s ease-in-out;
  height: 500%;
}

.section {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
