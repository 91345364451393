/* Header.css */
.header {
  color: #fff;
  padding: 7.5px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; /* Set a higher z-index value */
}

.header li {
  cursor: pointer;
}
