@font-face {
  font-family: "FuturaPTExtraBold";
  src: url("../src/assets/fonts/FuturaPTExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPTHeavy";
  src: url("../src/assets/fonts/FuturaPTHeavy.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPTMedium";
  src: url("../src/assets/fonts/FuturaPTMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPTLight";
  src: url("../src/assets/fonts/FuturaPTLight.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

.header-desktop {
  font-size: 3.75rem;
  line-height: 1;
}

.header-mobile {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.paragraph-desktop {
  font-size: 1.5rem;
  line-height: 2rem;
}

.paragraph-mobile {
  font-size: 0.8rem; /* 16px */
  line-height: 1.3rem; /* 24px */
}

.btn:hover {
  background-color: #ff6000;
}
